import React from "react"

import AuthProvider from '../components/auth';
import Loading from '../components/loading';

import '../components/layout';

const CallbackPage = () => {
  return(
    <AuthProvider>
      <Loading />
    </AuthProvider>
  );
};

export default CallbackPage;
